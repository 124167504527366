import {useRoute} from "vue-router/composables";

export const cnpjValidation = (cnpj = null) => {
    cnpj = cnpj.replace(/[^\d]+/g, "");

    if (cnpj.length != 14) return false;

    let tamanhoTotal = cnpj.length - 2;
    let cnpjSemDigitos = cnpj.substring(0, tamanhoTotal);
    let digitosVerificadores = cnpj.substring(tamanhoTotal);
    let soma = 0;
    let pos = tamanhoTotal - 7;
    let resultado;

    for (let i = tamanhoTotal; i >= 1; i--) {
        soma += cnpjSemDigitos.charAt(tamanhoTotal - i) * pos--;
        if (pos < 2) pos = 9;
    }

    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    if (resultado != digitosVerificadores.charAt(0)) return false;

    tamanhoTotal = tamanhoTotal + 1;
    cnpjSemDigitos = cnpj.substring(0, tamanhoTotal);
    soma = 0;
    pos = tamanhoTotal - 7;

    for (let i = tamanhoTotal; i >= 1; i--) {
        soma += cnpjSemDigitos.charAt(tamanhoTotal - i) * pos--;
        if (pos < 2) pos = 9;
    }

    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitosVerificadores.charAt(1)) return false;

    return true;
};

export const validateExistence = (field = null) => {
    return field !== "" && field !== null;
};

export const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
};


export const isAcl = (name) => {
    try {
        const aclbase64 = (localStorage.getItem('permissions'));
        const acl = JSON.parse(atob(aclbase64));
        // console.log(acl);
        return acl.includes(name);
    } catch (e) {
        console.error(e);
        return false;
    }
}

export const isAclArray = (name) => {
    try {
        const aclbase64 = (localStorage.getItem('permissions'));
        const acl = JSON.parse(atob(aclbase64));
        let result = false;
        for (let i = 0; i < name.length; i++) {
            if (acl.includes(name[i])) {
                result = true;
            }
        }
        return result;
    } catch (e) {
        console.error(e);
        return false;
    }
}

export const isAclArrayAndRoute = (routeX, name) => {
    try {
        const aclbase64 = (localStorage.getItem('permissions'));
        const acl = JSON.parse(atob(aclbase64));
        let result = false;
        for (let i = 0; i < name.length; i++) {
            if (acl.includes(name[i])) {
                result = true;
            }
        }
        const route = useRoute();

        if(route.name !== routeX){
            console.log('nao tem permissao');
            result = true;
        }

        return result;
    } catch (e) {
        console.error(e);
        return false;
    }
}