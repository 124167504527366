import Axios from "axios";

const domain = process.env.VUE_APP_DOMAIN;
const name = "schools_types";

const axiosInstance = Axios.create({
    headers: {
        "Authorization": `Bearer ${localStorage.getItem("jwtToken")}`,
        "user_id": localStorage.getItem("uid"),
    },
    baseURL: domain + "/v1_settings",
});

export const schoolsTypesFetch = async (id_school) => {
  try {
    const response = await axiosInstance({
      method: "get",
      url: `v1/${id_school}/${name}`,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const schoolsTypesCreate = async (id_school, payload) => {
  try {
    const response = await axiosInstance({
      method: "post",
      url: `v1/${id_school}/${name}/create`,
      data: payload,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const schoolsTypesUpdate = async (id_school, payload) => {
  try {
    const response = await axiosInstance({
      method: "put",
      url: `v1/${id_school}/${name}/update/${payload.id}`,
      data: payload,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const schoolsTypesDeletex = async (id_school, payload) => {
  try {
    const response = await axiosInstance({
      method: "delete",
      url: `v1/${id_school}/${name}/delete/${payload.id}`,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const schoolsTypesPaginate = async (id_school, payload) => {
  try {
    const queryString = payload.search
      ? `page=${payload.numPage}&search=${payload.search}`
      : `page=${payload.numPage}`;

    const response = await axiosInstance({
      method: "get",
      url: `v1/${id_school}/${name}/paginate`,
      params: payload,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const schoolsTypesRemove = async (id_school, payload) => {
  try {
    const response = await axiosInstance({
      method: "delete",
      url: `v1/${id_school}/${name}/remove/${payload.id}`,
    });
    return response;
  } catch (error) {
    return error;
  }
};
export const schoolsTypesTrash = async (id_school, payload) => {
  try {
    const queryString = payload.search
      ? `page=${payload.numPage}&search=${payload.search}`
      : `page=${payload.numPage}`;
    const response = await axiosInstance({
      method: "get",
      url: `v1/${id_school}/${name}/trash`,
      params: payload,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const schoolsTypesRestore = async (id_school, payload) => {
  try {
    const response = await axiosInstance({
      method: "put",
      url: `v1/${id_school}/${name}/restore/${payload.id}`,
      data: {id: payload.id},
    });
    return response;
  } catch (error) {
    return error;
  }
};
