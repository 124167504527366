import Axios from "axios";

const REQUEST =
  `${process.env.VUE_APP_DOMAIN}/v1_payments/v1/1/pay/gateways/`;

const axiosInstance = Axios.create({
    headers: {
        "Authorization": `Bearer ${localStorage.getItem("jwtToken")}`,
        "user_id": localStorage.getItem("uid"),
    },
    baseURL: REQUEST,
});

export const fetchAllGateways = async () => {
  try {
    const { data } = await axiosInstance({
      method: "get",
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const fetchGatewaysPayments = async ({
  search = "",
  page = 1,
  perPage = 10,
}) => {
  const queryString = search !== "" ? `?search=${search}&` : "?";

  try {
    const { data } = await axiosInstance({
      method: "get",
      url: `/paginate${queryString}page=${page}&per_page=${perPage}`,
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const fetchTrash = async ({ search = "", page = 1, perPage = 10 }) => {
  const queryString = search !== "" ? `?search=${search}&` : "?";

  try {
    const { data } = await axiosInstance({
      method: "get",
      url: `/trash/paginate${queryString}page=${page}&per_page=${perPage}`,
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const saveGatewaysPayments = async (payload) => {
  try {
    const { data } = await axiosInstance({
      method: payload.id ? "put" : "post",
      data: { ...payload },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const removeGatewaysPayments = async (payload) => {
  try {
    const { data } = await axiosInstance({
      method: "put",
      url: `/remove`,
      data: { ...payload },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const restoreGatewaysPayments = async (id) => {
  try {
    const { data } = await axiosInstance({
      method: "put",
      url: `/restore`,
      data: {
        id: id,
      },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const deleteGatewaysPayments = async (payload) => {
  try {
    const { data } = await axiosInstance({
      method: "delete",
      url: `/delete`,
      data: { ...payload },
    });

    return data;
  } catch (error) {
    return error;
  }
};
