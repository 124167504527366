import {formsCreate, formsCreatePeople, formsCreatePeopleValues, formsDeletex, formsFetch, formsFind, formsFindPeople, formsPaginate, formsPeoples, formsRemove, formsRestore, formsTrash, formsUpdate, formsUpdatePeople, formsUpdatePeopleValues} from "@/modules/forms/forms-service";

const state = {
    forms: [],
    form: {},
    formsPeoples: [],
    formPeople: {},
    paginate: {},
    load: null,
};
const idSchool = 1;
const getters = {
    getForms(state) {
        try{
            const result = state.forms.data.map((item) => ({
                ...item,
                showEdit: false,
            }));

            return result;
        }catch (e) {
            return [];
        }
    },
    getForm(state) {
        return state.form;
    },
    getFormsPeoples(state) {
        try {
            return state.formsPeoples.data;
        } catch (e) {
            return [];
        }
    },
    getFormPeople(state) {
        return state.formPeople;
    },
    getPaginate(state) {
        const data = {...state.paginate};
        return data;
    },
    getLoad(state) {
        return state.load;
    },
};

const mutations = {
    SET_FORMS(state, forms) {
        state.forms = forms;
    },
    SET_FORM(state, form) {
        state.form = form;
    },
    SET_FORMS_PEOPLES(state, formsPeoples) {
        state.formsPeoples = formsPeoples;
    },
    SET_FORM_PEOPLE(state, formPeople) {
        state.formPeople = formPeople
    },
    SET_PAGINATE(state, paginate) {
        state.paginate = {
            page: paginate.page,
            per_page: paginate.limit,
            total: paginate.total,
            total_pages: paginate.total_pages,
            //TODO: arrumar
            data: paginate.data
        };
    },
    SET_LOAD(state, load) {
        state.load = load;
    },
};

const actions = {
    setLoadNull({commit}) {
        commit("SET_LOAD", null);
    },
    async getForms({commit}) {
        try {
            const response = await formsFetch(idSchool);
            const {data} = response;
            commit("SET_FORMS", data);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async getForm({commit}, payload) {
        try {
            const response = await formsFind(idSchool, payload);
            const {data} = response;
            commit("SET_FORM", data);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async formsCreate({commit, dispatch}, payload) {
        try {
            //console.log('formsCreate',payload);
            const response = await formsCreate(idSchool, payload);
            dispatch("getForms", idSchool);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async formsUpdate({commit, dispatch}, payload) {
        try {
            const response = await formsUpdate(idSchool, payload);
            dispatch("getForms", idSchool);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async formsDeletex({commit}, query) {
        try {
            const response = await formsDeletex(idSchool, query);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async formsPaginate({commit}, query) {
        try {
            const response = await formsPaginate(idSchool, query);

            //console.log('response aqui',response);
            const {data} = response;
            commit("SET_PAGINATE", data);
            commit("SET_LOAD", response.status);
        } catch (error) {
            commit("SET_LOAD", null);
            console.log(error);
        }
    },

    async formsRemove({commit}, payload) {
        try {
            const response = await formsRemove(idSchool, payload);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },

    async formsTrash({commit}, query) {
        try {
            const response = await formsTrash(idSchool, query);
            commit("SET_PAGINATE", response.data);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async formsRestore({commit}, payload) {
        try {
            const response = await formsRestore(idSchool, payload);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async formsPeoples({commit}, payload) {
        try {
            const response = await formsPeoples(idSchool, payload);
            commit("SET_FORMS_PEOPLES", response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async getFormPeople({commit}, payload) {
        try {
            const response = await formsFindPeople(idSchool, payload);
            commit("SET_FORM_PEOPLE", response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async formsCreatePeople({commit}, payload) {
        try {
            const response = await formsCreatePeople(idSchool, payload);
            commit("SET_LOAD", response.status);
            return response;
        } catch (error) {
            console.log(error);
        }
    },
    async formsUpdatePeople({commit}, payload) {
        try {
            const response = await formsUpdatePeople(idSchool, payload);
            commit("SET_LOAD", response.status);
            return response;
        } catch (error) {
            console.log(error);
        }
    },
    //formsCreatePeopleValues
    async formsCreatePeopleValues({commit}, payload) {
        try {
            const response = await formsCreatePeopleValues(idSchool, payload);
            commit("SET_LOAD", response.status);
            return response;
        } catch (error) {
            console.log(error);
        }
    },
    async formsUpdatePeopleValues({commit}, payload) {
        try {
            const response = await formsUpdatePeopleValues(idSchool, payload);
            commit("SET_LOAD", response.status);
            return response;
        } catch (error) {
            console.log(error);
        }
    }
};

export default {
    namespaced: true,
    actions,
    getters,
    state,
    mutations,
};
