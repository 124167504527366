import labels from "@/modules/pay-products-types/pay-products-types-labels";
export default {
    path: "/pay-products-types",
    name: labels.plural+'s',
    meta: {
        authRequired: true,
    },
    component: () => import("./pay-products-types.vue")
}

