import Axios from "axios";

const domain = process.env.VUE_APP_DOMAIN;
const name = "forms";
const forms_peoples = "forms_peoples";
const forms_peoples_values = "forms_peoples_values";

const axiosInstance = Axios.create({
    headers: {
        "Authorization": `Bearer ${localStorage.getItem("jwtToken")}`,
        "user_id": localStorage.getItem("uid"),
    },
    baseURL: domain + "/v1_forms",
});

export const formsFetch = async (id_school) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `v1/${id_school}/${name}`,
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const formsFind = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `v1/${id_school}/${name}/${payload.id}`,
        });
        return response;
    } catch (error) {
        return error;
    }
}

export const formsCreate = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "post",
            url: `v1/${id_school}/${name}/create`,
            data: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const formsUpdate = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "put",
            url: `v1/${id_school}/${name}/update/${payload.id}`,
            data: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const formsDeletex = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "delete",
            url: `v1/${id_school}/${name}/delete/${payload.id}`,
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const formsPaginate = async (id_school, payload) => {
    try {
        const queryString = payload.search
            ? `page=${payload.numPage}&search=${payload.search}`
            : `page=${payload.numPage}`;

        const response = await axiosInstance({
            method: "get",
            url: `v1/${id_school}/${name}/paginate`,
            params: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const formsRemove = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "delete",
            url: `v1/${id_school}/${name}/remove/${payload.id}`,
        });
        return response;
    } catch (error) {
        return error;
    }
};
export const formsTrash = async (id_school, payload) => {
    try {
        const queryString = payload.search
            ? `page=${payload.numPage}&search=${payload.search}`
            : `page=${payload.numPage}`;
        const response = await axiosInstance({
            method: "get",
            url: `v1/${id_school}/${name}/trash`,
            params: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const formsRestore = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "put",
            url: `v1/${id_school}/${name}/restore/${payload.id}`,
            data: {id: payload.id},
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const formsPeoples = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `v1/${id_school}/${forms_peoples}`,
            params: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}

export const formsFindPeople = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `v1/${id_school}/${forms_peoples}/${payload.id}`,
        });
        return response;
    } catch (error) {
        return error;
    }
}

export const formsCreatePeople = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "post",
            url: `v1/${id_school}/${forms_peoples}/create`,
            data: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}

export const formsUpdatePeople = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "put",
            url: `v1/${id_school}/${forms_peoples}/update/${payload.id}`,
            data: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}

export const formsCreatePeopleValues = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "post",
            url: `v1/${id_school}/${forms_peoples_values}/create`,
            data: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}

export const formsUpdatePeopleValues = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "put",
            url: `v1/${id_school}/${forms_peoples_values}/update/${payload.id}`,
            data: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}