

export default [
    {
        path: "/student-assessment",
        name: 'student-assessment',
        meta: {
            authRequired: true,
        },
        component: () => import("./Assessments.vue")
    },
    //boletim
    {
        path: "/student-boletim",
        name: 'student-boletim',
        meta: {
            authRequired: true,
        },
        component: () => import("./Boletim.vue")
    },
    //calendar
    {
        path: "/student-calendar",
        name: 'student-calendar',
        meta: {
            authRequired: true,
        },
        component: () => import("./Calendar.vue")
    },
    //guideline
    {
        path: "/student-guideline",
        name: 'student-guideline',
        meta: {
            authRequired: true,
        },
        component: () => import("./Guideline.vue")
    },
    //services
    {
        path: "/student-services",
        name: 'student-services',
        meta: {
            authRequired: true,
        },
        component: () => import("./Services.vue")
    },
    //warning
    {
        path: "/student-warning",
        name: 'student-warning',
        meta: {
            authRequired: true,
        },
        component: () => import("./Warning.vue")
    }
]