import {
    getTrash as getTrashService,
    fetchTaxonomies,
    deleteTaxonomies,
    removeTaxonomies,
    restore as restoreService,
    createTaxonomies,
    updateTaxonomies, findTaxonomies, findTaxonomiesType, fetchAllTaxonomies,
} from "../services/services.js";
import {mountMessage} from "@/utils/MountMessage";
import formatToOptions from "../utils/formatToOptions.js";

const state = {
    taxonomies: [],
    taxonomiesAll: [],
    taxonomiesNav: [],
    taxonomiesOptions: [],
    isTrash: false,
    pagination: {},
    loading: {status: "idle"},
    formLoading: {status: "idle"},
    actionLoading: {status: "idle"},
};

const getters = {
    taxonomies(state) {
        return state.taxonomies;
    },

    taxonomiesAll(state) {
        return state.taxonomiesAll;
    },

    taxonomiesOptions(state) {
        return formatToOptions(state.taxonomiesOptions, "type", "id");
    },

    taxonomiesNav(state) {
        return state.taxonomiesNav;
    },

    isTrash(state) {
        return state.isTrash;
    },

    pagination(state) {
        return state.pagination;
    },

    isLoading(state) {
        return state.loading;
    },

    isFormSaving(state) {
        return state.formLoading;
    },

    isActionLoading(state) {
        return state.actionLoading;
    },
};

const mutations = {
    SET_TAXONOMIES(state, payload) {
        state.taxonomies = payload;
    },

    SET_TAXONOMIES_ALL(state, payload) {
        state.taxonomiesAll = payload;
    },

    SET_LOADING(state, payload) {
        state.loading = payload;
    },

    SET_SAVE_LOADING(state, payload) {
        state.formLoading = payload;
    },

    SET_ACTION_LOADING(state, payload) {
        state.actionLoading = payload;
    },

    SET_TAXONOMIES_PAGINATION(state, payload) {
        state.pagination = payload;
    },

    SET_TAXONOMIES_NAV(state, payload) {
        state.taxonomiesNav = payload;
    },

    SET_TAXONOMIES_OPTIONS(state, payload) {
        state.taxonomiesOptions = payload;
    },

    SET_IS_TRASH(state, payload) {
        state.isTrash = payload;
    },
};

const actions = {
    async getTaxonomies({commit, getters}, query) {
        commit("SET_LOADING", {status: "loading"});
        try {


            const response = await fetchTaxonomies({
                ...query,
                id_taxonomy_type: query.id_taxonomy_type,
            });

            const error = mountMessage(response);

            if (error.status === "error") throw error;
            else {
                commit("SET_IS_TRASH", false);
                commit("SET_TAXONOMIES", response.data);
                commit("SET_TAXONOMIES_PAGINATION", response.pagination);
                commit("SET_LOADING", {status: "ok"});
            }
        } catch (error) {
            //console.log("🚀 ~ getTaxonomies ~ error:", error)
            commit("SET_LOADING", error);
        }
    },

    async getAllTaxonomies({commit, getters}, query) {
        try {
            const response = await fetchAllTaxonomies({
                ...query,
            });
            const error = mountMessage(response);
            if (error.status === "error") throw error;
            else {

                commit("SET_TAXONOMIES_ALL", response);
                return response;
            }
        } catch (error) {
            commit("SET_LOADING", error);
        }
    },

    async getAllTaxonomiesDirect({commit, getters}, query) {
        try {
            const response = await fetchAllTaxonomies({
                ...query,
            });
            const error = mountMessage(response);
            if (error.status === "error") throw error;
            else {
                return response;
            }
        } catch (error) {
            commit("SET_LOADING", error);
        }
    },

    async setTaxonomiesNav({commit}, payload) {
        commit("SET_TAXONOMIES_OPTIONS", payload);
        commit("SET_TAXONOMIES_NAV", payload);
    },

    async getTrash({commit}, query = {}) {
        commit("SET_LOADING", {status: "loading"});
        try {

            const response = await getTrashService({
                ...query,
                id_taxonomy_type: query.id_taxonomy_type,
            });

            const error = mountMessage(response);

            if (error.status === "error") throw error;
            else {
                commit("SET_IS_TRASH", true);
                commit("SET_TAXONOMIES", response.data);
                commit("SET_TAXONOMIES_PAGINATION", response.pagination);
                commit("SET_LOADING", {status: "ok"});
            }
        } catch (error) {
            commit("SET_LOADING", error);
        }
    },

    async save({commit, dispatch}, payload) {
        commit("SET_SAVE_LOADING", {status: "loading"});
        try {

            delete payload.image_view;
            delete payload.video_view;
            delete payload.audio_view;
            delete payload.deleted_at;
            delete payload.taxonomies_types;
            delete payload.taxonomies;
            delete payload._showDetails;


            const form = new FormData();
            Object.keys(payload).forEach((key) => {
                if(payload[key]){
                    if (key === "image" || key === "video" || key === "audio") {
                        form.append(key, payload[key]);
                    } else {
                        form.append(key, payload[key]);
                    }
                }else{
                    form.append(key, "");
                }
            });



            const response = parseInt(payload.id) > 0
                ? await updateTaxonomies(payload.id, form)
                : await createTaxonomies(form);

            const error = mountMessage(response);

            if (error.status === "error") throw error;
            else {
                dispatch("getTaxonomies", { id_taxonomy_type: payload.id_taxonomy_type });
                commit("SET_SAVE_LOADING", {status: "ok"});
            }
        } catch (error) {
            console.log("🚀 ~ save ~ error:", error)
            commit("SET_SAVE_LOADING", error);
        }
    },

    async remove({commit, dispatch}, payload) {
        commit("SET_ACTION_LOADING", {status: "loading"});
        try {
            const response = await removeTaxonomies(payload);

            const error = mountMessage(response);

            if (error.status === "error") throw error;
            else {
                dispatch("getTaxonomies", {});
                commit("SET_ACTION_LOADING", {status: "ok"});
            }
        } catch (error) {
            commit("SET_ACTION_LOADING", error);
        }
    },

    async restore({commit, dispatch}, payload) {
        commit("SET_ACTION_LOADING", {status: "loading"});
        try {
            const response = await restoreService(payload);

            const error = mountMessage(response);

            if (error.status === "error") throw error;
            else {
                dispatch("getTrash", {});
                commit("SET_ACTION_LOADING", {status: "ok"});
            }
        } catch (error) {
            commit("SET_ACTION_LOADING", error);
        }
    },

    async delete({commit, dispatch}, payload) {
        commit("SET_ACTION_LOADING", {status: "loading"});
        try {
            const response = await deleteTaxonomies(payload);

            const error = mountMessage(response);

            if (error.status === "error") throw error;
            else {
                dispatch("getTrash", {});
                commit("SET_ACTION_LOADING", {status: "ok"});
            }
        } catch (error) {
            commit("SET_ACTION_LOADING", error);
        }
    },

    async findTaxonomiesType({commit}, id) {
        commit("SET_LOADING", {status: "loading"});
        try {
            const response = await findTaxonomiesType(id);

            const error = mountMessage(response);

            if (error.status === "error") throw error;
            else {
                commit("SET_LOADING", {status: "ok"});
                return response;
            }
        } catch (error) {
            commit("SET_LOADING", error);
        }
    }
};

const setSelectedTaxonomy = (taxNav, title) => {
    const [taxonomy] = taxNav.filter((el) => el.type === title) || {};
    //console.log("🚀 ~ setSelectedTaxonomy ~ taxonomy:", taxonomy)

    localStorage.setItem("taxonomySelected", JSON.stringify(taxonomy));

    return taxonomy.id;
};

const getTaxonomySelected = () => {
    const tax = JSON.parse(localStorage.getItem("taxonomySelected"));
    return tax ? tax : {};
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
